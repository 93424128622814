import React from "react"
import { PageProps } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Content from "react-bulma-components/lib/components/content"

const Privacy = (props: PageProps) => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO
        title={t("pages.Privacy.seo.title")}
        description={t("pages.Privacy.seo.description")}
      />
      <Section>
        <Container>
          <Content>
            <h1>{t("pages.Privacy.heading")}</h1>

            <p>
              This Privacy Policy governs the manner in which iynk Ltd collects,
              uses, maintains and discloses information collected from users
              (each, a "User") of the https://www.iynk.com website and the iynk
              mobile application ("Site"). This privacy policy applies to the
              Site and all products and services offered by iynk Ltd ("us",
              "we", or "our").
            </p>

            <h3>Personal identification information</h3>
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, place an order, and in connection
              with other activities, services, features or resources we make
              available on our Site. Users may be asked for, as appropriate,
              name, email address. Users may, however, visit our Site
              anonymously. We will collect personal identification information
              from Users only if they voluntarily submit such information to us.
              Users can always refuse to supply personally identification
              information, except that it may prevent them from engaging in
              certain Site related activities. We may use your Personal Data to
              contact you with newsletters, marketing or promotional materials
              and other information that may be of interest to you. You may opt
              out of receiving any, or all, of these communications from us by
              following the unsubscribe link or instructions provided in any
              email we send or by contacting us.
            </p>

            <h3>Non-personal identification information</h3>
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>

            <h3>Location Data</h3>
            <p>
              With your permission, we may use and store information about your
              location. We use this data to provide features of our Service, to
              improve and customize our Service. You can enable or disable
              location services when you use our Service at any time, through
              your device settings.
            </p>

            <h3>Web browser cookies</h3>
            <p>
              Our Site may use "cookies" to enhance User experience. User's web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>

            <h3>How we use collected information</h3>
            <p>
              iynk Ltd may collect and use Users personal information for the
              following purposes:
            </p>

            <ul>
              <li>
                To run and operate our Site -We may need your information
                display content on the Site correctly.
              </li>
              <li>
                To improve customer service - Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </li>
              <li>
                To personalize user experience - We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                To improve our Site We may use feedback you provide to improve
                our products and services.
              </li>
              <li>
                To process payments We may use the information Users provide
                about themselves when placing an order only to provide service
                to that order. We do not share this information with outside
                parties except to the extent necessary to provide the service.
              </li>
              <li>
                To run a promotion, contest, survey or other Site feature To
                send Users information they agreed to receive about topics we
                think will be of interest to them.
              </li>
              <li>
                To manage product reviews and ratings - We may use third party
                service providers to help us manage product reviews and ratings
                left by Users.
              </li>
              <li>
                To send periodic emails - We may use the email address to send
                User information and updates pertaining to their order. It may
                also be used to respond to their inquiries, questions, and/or
                other requests.
              </li>
            </ul>

            <h3>General Data Protection Regulation (GDPR)</h3>
            <p>
              If you are from the European Economic Area (EEA), iynk Ltd legal
              basis for collecting and using the personal information described
              in this Privacy Policy depends on the Personal Data we collect and
              the specific context in which we collect it. We may process your
              Personal Data because:
            </p>

            <ul>
              <li>We need to perform a contract with you</li>
              <li>You have given us permission to do so</li>
              <li>
                The processing is in our legitimate interests and it’s not
                overridden by your rights
              </li>
              <li>For payment processing purposes</li>
              <li>To comply with the law</li>
            </ul>

            <p>
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please contact
              us. You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>

            <h3>How we protect your information</h3>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>

            <h3>Sharing your personal information</h3>
            <p>
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above. We may
              use third party service providers to help us operate our business
              and the Site or administer activities on our behalf, such as
              sending out newsletters or surveys. We may share your information
              with these third parties for those limited purposes provided that
              you have given us your permission.
            </p>

            <h3>Electronic newsletters</h3>
            <p>
              If User decides to opt-in to our mailing list, they will receive
              emails that may include company news, updates, related product or
              service information, etc. We may use third party service providers
              to help us operate our business and the Site or administer
              activities on our behalf, such as sending out newsletters or
              surveys. We may share your information with these third parties
              for those limited purposes provided that you have given us your
              permission.
            </p>

            <h3>Third party websites</h3>
            <p>
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website's own terms and policies.
            </p>

            <h3>Advertising</h3>
            <p>
              Ads appearing on our site may be delivered to Users by advertising
              partners, who may set cookies. These cookies allow the ad server
              to recognize your computer each time they send you an online
              advertisement to compile non personal identification information
              about you or others who use your computer. This information allows
              ad networks to, among other things, deliver targeted
              advertisements that they believe will be of most interest to you.
              This privacy policy does not cover the use of cookies by any
              advertisers.
            </p>

            <h3>Google Analytics</h3>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network. For more information on the privacy practices of Google,
              please visit the Google Privacy Terms web page:
              http://www.google.com/intl/en/policies/privacy/.
            </p>

            <h3>Google Adsense</h3>
            <p>
              Some of the ads may be served by Google. Google's use of the DART
              cookie enables it to serve ads to Users based on their visit to
              our Site and other sites on the Internet. DART uses "non
              personally identifiable information" and does NOT track personal
              information about you, such as your name, email address, physical
              address, etc. You may opt out of the use of the DART cookie by
              visiting the Google ad and content network privacy policy at
              http://www.google.com/privacy_ads.html
            </p>

            <h3>Compliance with children's online privacy protection act</h3>
            <p>
              Protecting the privacy of the very young is especially important.
              For that reason, we never collect or maintain information at our
              Site from those we actually know are under 13, and no part of our
              website is structured to attract anyone under 13. Changes to this
              privacy policy iynk Ltd has the discretion to update this privacy
              policy at any time. When we do, we will post a notification on the
              main page of our Site. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>

            <h3>Your acceptance of these terms</h3>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes. Contacting
              us We may update our Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p>

            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at hello@iynk.com.
            </p>
          </Content>
        </Container>
      </Section>
    </Layout>
  )
}

export default Privacy
